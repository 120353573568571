import React, {useEffect, useRef, useState} from "react";
import {
    DriveGallery,
    FloorplanSectionSkeleton,
    FloorplanShortTerm,
    getShortTermFloorplansByPropertyId,
    PropertyDetails,
    PropertyLocation,
    ShortTermFloorplansSection,
    ShortTermPricingCard,
    SummerHouseFeatures,
    TeamSection,
} from "@renaissancerentals/renaissance-component";
import {PropertyHero} from "./PropertyHero";
import {Li, Ul} from "@contentmunch/muncher-ui";

export const SummerHouseShortTerm: React.FC<SummerHouseShortTermProps> = (
    {property, isLoading, handleRefToContact}) => {
    const [isShortTermLoading, setIsShortTermLoading] = useState(true);
    const [floorplanShortTerms, setFloorplanShortTerms] = useState<FloorplanShortTerm[]>([]);
    const shortTermPropertyId = "summer-house-short-term";

    const amenitiesRef = useRef<HTMLDivElement>(null);
    const pricingRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const handleRefToPricing = () => {
        pricingRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToAmenities = () => {
        amenitiesRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    useEffect(() => {
        getShortTermFloorplansByPropertyId(shortTermPropertyId).then(value => {
            setFloorplanShortTerms(value);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsShortTermLoading(false);
        });
    }, []);
    return (
        <section className="section-property">
            <PropertyHero property={property}/>
            <nav className="property-nav">
                <div className="container">
                    <h5><a href="#amenities" onClick={handleRefToAmenities}>amenities</a></h5>
                    <h5><a href="#pricing" onClick={handleRefToPricing}>pricing</a></h5>
                    <h5><a href="#location" onClick={handleRefToMap}>location</a></h5>
                </div>
            </nav>
            <SummerHouseFeatures/>


            <div ref={amenitiesRef} id="amenities" className="reference">
            </div>
            <div className="container amenities-section">
                <h2>Amenities</h2>

                <Ul>
                    {property.amenities.map(amenity =>
                        <Li key={"amenity-" + amenity.id}
                            isFeatured={amenity.featured}>{amenity.name}</Li>
                    )}
                </Ul>


            </div>


            <div ref={pricingRef} id="pricing" className="reference">
            </div>

            <ShortTermPricingCard floorplans={floorplanShortTerms.sort((a, b) => a.bedroom - b.bedroom)}/>

            <div className="container">
                <h2>Community Photos</h2>
                <DriveGallery
                    driveId={property.photosFolderId}
                    initialSize={6}
                    type="simple"
                    showName={false}
                    propertyId={property.id}
                />
            </div>
            {isShortTermLoading ?
                <FloorplanSectionSkeleton/> :
                <ShortTermFloorplansSection floorplans={floorplanShortTerms} propertyId={shortTermPropertyId}
                                            title="Short Term FloorPlans"/>
            }
            <div ref={mapRef} id="location" className="reference">

            </div>
            <div className="container location-section">
                <h2>Location</h2>
            </div>
            <PropertyLocation property={property} isLoading={isLoading} handleRefToContact={handleRefToContact}/>


            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={property.id}/>

        </section>
    );
}

export interface SummerHouseShortTermProps {
    isLoading: boolean;
    property: PropertyDetails;
    handleRefToContact: () => void;
}
